<template>
  <div class="service-container">
    <transition name="fade" mode="out-in">
      <router-view> </router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Service",
  metaInfo: {
    title: "บริการ",
    meta: [
      {
        name: "og:title",
        content: "บริการ | Revealing Clinic"
      }
    ]
  }
};
</script>

<style lang="scss" scoped></style>
